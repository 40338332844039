const beer = [
  {
    id: "beer",
    isInline: true,

    title: {
      es: "Cerveza oficial",
      cat: "Cervesa oficial",
    },
    items: [
      {
        link: {
          es: "https://www.cervezarosablanca.es/",
          cat: "https://www.cervezarosablanca.es/ca",
        },
        title: "Rosa Blanca cerveza",
        image: "beer-rosa-blanca.png",
        size: 1.2,
        original: true,
      },
    ],
  },
];

export default beer;
