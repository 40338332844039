const partnerTecnologic = [
  {
    id: "partnerTecnologic",
    isInline: true,
    title: {
      es: "Socio tecnológico",
      cat: "Soci tecnològic",
    },
    items: [
      {
        link: { es: "https://lomusic.es/", cat: "https://lomusic.es/" },
        title: "Lo Music",
        image: "partner-tecnologic-lomusic.png",
        size: 1.3,
        original: true,
      },
    ],
  },
];

export default partnerTecnologic;
