<template>
  <a
    class="sponsors-section__item__images__image"
    target="_blank"
    referer="nofollow"
    :href="item.link[currentLang]"
  >
    <img
      :src="getImage(item.image)"
      :alt="item.title"
      loading="lazy"
      :style="item.size ? `height: calc(46px * ${item.size});` : ''"
      :class="item.original ? 'original' : ''"
    />
  </a>
</template>

<script>
import { defineComponent } from "vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";

export default defineComponent({
  props: { item: Object },
  setup() {
    const { currentLang } = useRouteWithLang();

    function getImage(image) {
      var images = require.context(
        "@/assets/sponsors/",
        false,
        /\.(png|svg|jpg)$/
      );
      return images("./" + image);
    }
    return {
      getImage,
      currentLang,
    };
  },
});
</script>
