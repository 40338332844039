const colaborationOf = [
  {
    id: "colaborationOf",
    isInline: true,
    title: {
      es: "Con la colaboración de",
      cat: "Amb la col·laboració de",
    },
    items: [
      {
        link: { es: "https://accec.cat/es/", cat: "http://accec.cat/" },
        title:
          "Associació Catalana de la Crítica I L'escriptura Cinematográfica",
        image: "colaboration-of-accec.jpg",
        size: 1.3,
        original: true,
      },
      {
        link: {
          es: "http://www.illesbalearsfilmfestivals.com/es/illes-balears-film-festivals-es/",
          cat: "http://www.illesbalearsfilmfestivals.com/festivals/",
        },
        title: "Illes Balears Film Festival",
        image: "colaboration-of-ibff.jpg",
        size: 1.3,
        original: true,
      },
      {
        link: {
          es: "https://catalunyafilmfestivals.com/es/",
          cat: "https://catalunyafilmfestivals.com/",
        },
        title: "Catalunya Film Festivals",
        image: "colaboration-of-catalunya-film-festival.png",
        size: 1,
        original: true,
      },
    ],
  },
];

export default colaborationOf;
