const catering = [
  {
    id: "catering",
    isInline: true,
    title: {
      es: "Catering oficial",
      cat: "Catering oficial",
    },
    items: [
      {
        link: {
          es: "https://www.thebarbara.es/",
          cat: "https://www.thebarbara.es/",
        },
        title: "Agapita Martínez",
        image: "agapita-martinez.png",
        size: 1,
        original: true,
      },
    ],
  },
];

export default catering;
