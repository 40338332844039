const medium = [
  {
    id: "medium",
    isInline: true,
    title: {
      es: "Medio oficial",
      cat: "Mitjà oficial",
    },
    items: [
      {
        class: "pl-4 pt-4 pr-4",

        link: { es: "https://ib3.org/", cat: "https://ib3.org/" },
        title: "IB3 Ens Públic de Radio i Televisió de les Illes Balears",
        image: "medium-b.png",
        size: 1.1,
        original: true,
      },
    ],
  },
];

export default medium;
