<template>
  <div class="w-full border-t border-opacity-25 bg-white">
    <div
      class="
        sponsors-section
        section-wrapper
        max-w-container
        px-container
        py-20
      "
    >
      <div
        :class="newSponsorsSection.class"
        :key="'sponsor-section-' + index"
        v-for="(newSponsorsSection, index) in newSponsorsSections"
      >
        <SponsorsSectionItem :sponsors="newSponsorsSection.sponsors" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import newSponsorsSections from "@/data/new-sponsors";

import SponsorsSectionItem from "@/components/SponsorsSectionItem.vue";

export default defineComponent({
  components: { SponsorsSectionItem },
  setup() {
    return {
      newSponsorsSections,
    };
  },
});
</script>

<style lang="scss">
.sponsors-section {
  flex-wrap: wrap;
  margin-bottom: -32px;

  &__item {
    flex-shrink: 0;
    max-width: 100%;
    padding-bottom: 50px;
    padding-right: 60px;
    &__title {
      font-size: 1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      margin-bottom: 30px;
      @apply text-amff-navi;
    }
    &__images {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 100%;
      &--inline-flex {
        display: inline-flex;
      }
      &__image img {
        color: black;
        margin-right: 50px;
        filter: grayscale(100%) invert(1);
        height: 46px;
        width: auto;
        max-width: 100%;
        margin-bottom: 34px;
        &.original {
          filter: grayscale(100%) invert(0);
        }
      }
      &__image:last-child {
        img {
          margin-right: 0px;
        }
      }
      &__image:only-child {
        margin: auto;
        img {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
