const atlantidaMallorcaTalentsLab = [
  {
    id: "atlantidaMallorcaTalentsLab",
    isInline: true,
    title: {
      es: "Atlàntida Mallorca Talents Lab",
      cat: "Atlàntida Mallorca Talents Lab",
    },
    items: [
      {
        title: "Europa Creativa Desk - MEDIA Catalunya",
        image: "amtl-europa-creativa.png",
        size: 1.3,
        original: true,
        link: {
          cat: "https://www.europacreativamedia.cat/",
          es: "https://www.europacreativamedia.cat/",
        },
      },
      {
        title: "Europa Creativa - Oficina MEDIA España - Cofinanciado por la Unión Europea",
        image: "oficina-españa.png",
        size: 1.3,
        original: true,
        link: {
          cat: "https://www.oficinamediaespana.eu/",
          es: "https://www.oficinamediaespana.eu/",
        },
      },
      // {
      //   link: {
      //     es: "https://www.oficinamediaespana.eu/",
      //     cat: "https://www.oficinamediaespana.eu/",
      //   },
      //   title: "Oficina MEDIA España",
      //   image: "amtl-oficina-media.jpg",
      //   size: 1.4,
      //   original: true,
      // },
      {
        link: {
          es: "https://elasticafilms.com",
          cat: "https://elasticafilms.com",
        },
        title: "Elastica Films",
        image: "amtl-elastical-films.png",
        size: 0.4,
        original: true,
      },
      {
        link: {
          es: "https://www.deluxe-spain.com/es",
          cat: "https://www.deluxe-spain.com/es",
        },
        title: "DELUXE",
        image: "amtl-deluxe.png",
        size: 1.2,
        original: true,
      },
      {
        link: {
          es: "https://mafiz.es/es",
          cat: "https://mafiz.es/es",
        },
        title: "MAFÍZ - Festival de Málaga",
        image: "mafiz.png",
        size: 1.3,
        original: true,
      },
      {
        link: {
          es: "https://www.abycine.com/",
          cat: "https://www.abycine.com",
        },
        title: "ABYCINE",
        image: "abycine.png",
        size: 0.8,
        original: true,
      },
    ],
  },
];

export default atlantidaMallorcaTalentsLab;
