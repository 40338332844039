const officialCar = [
  {
    id: "officialCar",
    isInline: true,
    title: {
      es: "Coche oficial",
      cat: "Cotxe oficial",
    },
    items: [
      {
        title: "Audi España",
        image: "car-audi.jpg",
        size: 1.2,
        link: {
          cat: "https://www.audi.es/",
          es: "https://www.audi.es/",
        },
        original: true,
      },
    ],
  },
];

export default officialCar;
