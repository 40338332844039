<template>
  <div
    :key="keySection"
    class="sponsors-section__item"
    v-for="(sponsorSection, keySection) in sponsors"
  >
    <div class="sponsors-section__item__title">
      {{ sponsorSection.title[currentLang] }}
    </div>
    <div
      class="sponsors-section__item__images"
      :class="{
        'sponsors-section__item__images--inline-flex': sponsorSection.isInline,
      }"
    >
      <SponsorsSectionItemImage
        v-for="sponsor in sponsorSection.items"
        :key="sponsor.image"
        :item="sponsor"
        :class="sponsor.class"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import SponsorsSectionItemImage from "@/components/SponsorsSectionItemImage.vue";

export default defineComponent({
  components: { SponsorsSectionItemImage },
  props: { sponsors: Array },
  setup() {
    const { currentLang } = useRouteWithLang();

    return {
      currentLang,
    };
  },
});
</script>
