
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: { type: String },
    imageSrc: { type: String },
    imageSrcset: { type: String },
    isRotated: { type: Boolean, default: window.innerWidth >= 1024 },
    rotateDirection: { type: String, default: "left" },
  },
});
