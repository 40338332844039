<template>
  <div>
    <HomeHero />
    <PostsBlock />
  </div>

  <!--<EventsBlock
    v-if="featuredEventsIds.length"
    :events="featuredEvents"
    :title="$t('home.featured_events')"
  />-->
  <!-- <FestivalEventsOverview />
  <FollowFestival /> -->
  <!-- <SponsorsSection /> -->
  <FestivalEventsOverview />
  <SponsorsSectionNew />
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import HomeHero from "@/components/home/HomeHero.vue";
import PostsBlock from "@/components/PostsBlock.vue";
//import EventsBlock from "@/components/EventsBlock.vue";
import FestivalEventsOverview from "@/components/FestivalEventsOverview.vue";
// import FollowFestival from "@/components/FollowFestival.vue";
//import SponsorsSection from "@/components/SponsorsSection.vue";
import SponsorsSectionNew from "@/components/SponsorsSectionNew.vue";
import featuredEventsIds from "@/data/featuredEventsIds.js";
import { useEvents } from "@/composables/useEvents";

export default defineComponent({
  components: {
    HomeHero,
    PostsBlock,
    //EventsBlock,
    FestivalEventsOverview,
    // FollowFestival,
    //SponsorsSection,
    SponsorsSectionNew,
  },
  setup() {
    const { fetchEvents, events: _events } = useEvents();
    onMounted(async () => {
      if (!_events.value.length) {
        await fetchEvents();
      }
    });
    const featuredEvents = computed(() =>
      _events.value
        .filter((e) => featuredEventsIds.includes(e.id))
        .sort((a, b) =>
          featuredEventsIds.indexOf(a.id) < featuredEventsIds.indexOf(b.id)
            ? -1
            : 1
        )
    );
    return {
      featuredEventsIds,
      featuredEvents,
    };
  },
});
</script>
