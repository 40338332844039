const specialColaborationOf = [
  {
    id: "specialColaborationOf",
    isInline: true,
    title: {
      es: "Con la colaboración especial de ",
      cat: "Amb la col·laboració especial de",
    },
    items: [
      {
        title: "DAMA",
        image: "colaboration-of-dama.png",
        size: 0.6,
        original: true,
        link: {
          cat: "https://damautor.es",
          es: "https://damautor.es",
        },
      },
      {
        link: {
          es: "https://uned-illesbalears.net/",
          cat: "https://uned-illesbalears.net/ca/",
        },
        title: "UNED - Illes Balears",
        image: "special-collab-uned.png",
        size: 1,
        original: true,
      },
      {
        title: "ICEC",
        image: "support-of-icec.png",
        size: 1.5,
        original: true,
        link: {
          cat: "https://icec.gencat.cat/ca/inici",
          es: "https://icec.gencat.cat/ca/inici",
        },
      },
      {
        title: "Acción Cultural Española",
        image: "support-of-ace.png",
        size: 1.5,
        original: true,
        link: {
          cat: "https://www.accioncultural.es",
          es: "https://www.accioncultural.es",
        },
      },
      {
        link: {
          es: "https://mnstudios.es",
          cat: "https://mnstudios.es",
        },
        title: "MN Studios",
        image: "colaboration-of-mn-studios.png",
        size: 2,
        original: true,
      },
      {
        link: {
          es: "https://mallorcafilmschool.es",
          cat: "https://mallorcafilmschool.es",
        },
        title: "Mallorca Film School",
        image: "colaboration-of-film-school.png",
        size: 1,
        original: true,
      },
      // {
      //   link: {
      //     es: "https://www.norway.no/es/spain/",
      //     cat: "https://www.norway.no/es/spain/",
      //   },
      //   title: "Embajada de Noruega",
      //   image: "embajada-noruega.png",
      //   size: 1,
      //   original: true,
      // },
      // {
      //   link: {
      //     es: "",
      //     cat: "",
      //   },
      //   title: "Institute Français",
      //   image: "institute-francais.png",
      //   size: 1.7,
      //   original: true,
      // },
      {
        link: {
          es: "https://caneduardo.com",
          cat: "https://caneduardo.com",
        },
        title: "Can'Eduardo",
        image: "can-eduardo.png",
        size: 0.4,
        original: true,
      },
      {
        link: {
          es: "https://cefmallorca.com",
          cat: "https://cefmallorca.com",
        },
        title: "CEF Escuela de artes audiovisuales",
        image: "cef.png",
        size: 0.8,
        original: true,
      },
      {
        link: {
          es: "https://www.thesocialhub.co/es/",
          cat: "https://www.thesocialhub.co/es/",
        },
        title: "The Social Hub",
        image: "social-hub.png",
        size: 1.5,
        original: true,
      },
      // {
      //   link: {
      //     es: "https://www.nec.com/",
      //     cat: "https://www.nec.com/",
      //   },
      //   title: "Nec",
      //   image: "nec-3-logo-svg-vector.png",
      //   size: 0.9,
      //   original: true,
      // },
    ],
  },
];

export default specialColaborationOf;
