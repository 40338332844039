import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full h-full opacity-90 overflow-hidden absolute" }
const _hoisted_2 = { class: "relative pt-36 pb-20" }
const _hoisted_3 = { class: "md:flex justify-center mt-8" }
const _hoisted_4 = { class: "flex flex-col mx-4" }
const _hoisted_5 = { class: "text-center p-2 pt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_HeroFestivalSlogan = _resolveComponent("HeroFestivalSlogan")
  const _component_TrailerModal = _resolveComponent("TrailerModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: "relative overflow-hidden",
      style: {
      marginTop: `calc(-1 * var(--header-height))`,
    }
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("img", {
          src: `${_ctx.baseURL}img/AMFF-2024-background.jpg`,
          style: {"height":"100% !important","width":"100% !important","object-fit":"cover !important"},
          alt: ""
        }, null, 8, ["src"])
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_HeroFestivalSlogan, {
          texts: ['Movies.', 'Music.', 'Talks.'],
          color: "amff-pink",
          isMobile: _ctx.isMobile
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    as: _ctx.AnchorLink,
                    to: { name: 'events' },
                    color: "amff-white",
                    class: "text-sm text-md@md mb-2 text-amff-white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("home.discover_music")), 1)
                    ]),
                    _: 1
                  }, 8, ["as"])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["texts", "isMobile"])
      ])
    ], 4),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_TrailerModal, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isModalOpen = false)),
          src: `${_ctx.baseURL}video/aftermovie-es-filmin.mp4`
        }, null, 8, ["src"]))
      : _createCommentVNode("", true)
  ], 64))
}