const hotels = [
  {
    id: "hotels",
    isInline: true,
    title: {
      es: "Hoteles AMFF",
      cat: "Hoteles AMFF",
    },
    items: [
      {
        link: {
          es: "https://www.hmhotels.com/",
          cat: "https://www.hmhotels.com/",
        },
        title: "HM hotels",
        image: "hotels-hm-hotels.jpg",
        original: true,
        size: 0.8,
      },
    ],
  },
];

export default hotels;
