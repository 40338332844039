
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    texts: { type: Array },
    color: { type: String },
    isMobile: { type: Boolean },
    isOnline: { type: Boolean },
  },
  setup() {
    return {
      baseURL: process.env.BASE_URL,
    };
  },
});
