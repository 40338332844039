import projectOf from "./new-sponsors/project-of";
import financingOf from "./new-sponsors/financing-of";
import sponsorshipsOf from "./new-sponsors/sponsorsships-of";
import supportOf from "./new-sponsors/support-of";
import partnerTecnologic from "./new-sponsors/partner-tecnologic";
import production from "./new-sponsors/production";
import hotels from "./new-sponsors/hotels";
import car from "./new-sponsors/car";
import catering from "./new-sponsors/catering";
import beer from "./new-sponsors/beer";
// import soda from "./new-sponsors/soda";
import specialColaborationOf from "./new-sponsors/special-colaboration-of";
import medium from "./new-sponsors/medium";
import bcnSede from "./new-sponsors/bcn-sede";
import atlantidaMallorcaTalentsLab from "./new-sponsors/atlantida-mallorca-talents-lab";
import colaborationOf from "./new-sponsors/colaboration-of";

const newSponsors = [
  { sponsors: projectOf },
  { class: "flex flex-wrap", sponsors: [...financingOf, ...sponsorshipsOf] },
  { sponsors: supportOf },
  {
    class: "flex flex-wrap",
    sponsors: [
      ...hotels,
      ...car,
      // ...catering,
      ...beer,
      // ...soda,
    ],
  },
  {
    class: "flex flex-wrap",
    sponsors: [...partnerTecnologic, ...production, ...medium],
  },
  {
    class: "flex flex-wrap",
    sponsors: [
      ...specialColaborationOf,
      ...catering,
      // ...bcnSede
    ],
  },
  {
    class: "flex flex-wrap",
    sponsors: [...atlantidaMallorcaTalentsLab, ...colaborationOf],
  },
  // { sponsors: colaborationOf },
];

export default newSponsors;
