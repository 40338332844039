const projectOf = [
  {
    id: "projectOf",
    isInline: true,
    title: {
      es: "Un proyecto de",
      cat: "Un projecte de",
    },
    items: [
      {
        title: "Filmin",
        image: "0-filmin.svg",
        size: 1,
        link: {
          cat: "http://www.filmin.es",
          es: "http://www.filmin.es",
        },
      },
    ],
  },
];

export default projectOf;
