const sponsorshipsOf = [
  {
    id: "sponsorshipsOf",
    isInline: true,
    title: {
      es: "Con el patrocinio de",
      cat: "Amb el patrocini de",
    },
    items: [
      {
        title: "Fundació Mallorca Turisme",
        image: "mallorca-illes-balears-black.png",
        size: 1.2,
        original: true,
        link: {
          cat: "https://fundaciomallorcaturisme.net/",
          es: "https://fundaciomallorcaturisme.net/es/",
        },
      },
      {
        title: "Fundació Mallorca Turisme",
        image: "sponsorsships-of-fundacio-mallorca-turisme.jpg",
        size: 0.8,
        original: true,
        link: {
          cat: "https://fundaciomallorcaturisme.net/",
          es: "https://fundaciomallorcaturisme.net/es/",
        },
      },
    ],
  },
];

export default sponsorshipsOf;
