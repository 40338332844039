const financingOf = [
  {
    id: "financingOf",
    isInline: true,
    title: {
      es: "Con la financiación de",
      cat: "Amb el finançament de",
    },
    items: [
      {
        title:
          "Consellería de Turisme, Cultura i Esports - Govern de Illes Balears",
        image: "financing-of-goib.png",
        size: 1.3,
        original: true,
        link: {
          cat: "https://www.caib.es/webgoib/inici",
          es: "https://www.caib.es/webgoib/es/inicio",
        },
      },
    ],
  },
];

export default financingOf;
