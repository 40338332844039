const production = [
  {
    id: "production",
    isInline: true,
    title: {
      es: "Producción",
      cat: "Producción",
    },
    items: [
      {
        link: {
          es: "http://powerhousehub.eu/",
          cat: "https://powerhousehub.eu/ca/",
        },
        title: "Powerhouse Hub",
        image: "production-power-house.jpg",
        size: 1,
        original: true,
      },
    ],
  },
];

export default production;
