const supportOf = [
  {
    id: "supportOf",
    isInline: true,
    title: {
      es: "Con el apoyo de",
      cat: "Amb el suport de",
    },
    items: [
      {
        title: "Ministerio de Cultura y Deporte ICAA",
        image: "support-of-gobierno-de-españa.png",
        size: 1.2,
        original: true,
        link: {
          cat: "https://www.culturaydeporte.gob.es/cultura/areas/cine/inicio.html",
          es: "https://www.culturaydeporte.gob.es/cultura/areas/cine/inicio.html",
        },
      },
      {
        title: "Plan de Recuperación, Transformación y Resilencia",
        image: "plan_recuperacion.png",
        size: 1.4,
        original: true,
        link: {
          cat: "https://planderecuperacion.gob.es",
          es: "https://planderecuperacion.gob.es",
        },
      },
      {
        title: "Financiado por la Unión Europea",
        image: "union_europea.png",
        size: 1,
        original: true,
        link: {
          cat: "https://european-union.europa.eu/index_es",
          es: "https://european-union.europa.eu/index_es",
        },
      },

      {
        title: "Ajuntament de Palma",
        image: "ayuntamiento-palma.png",
        size: 1.5,
        original: true,
        link: {
          cat: "https://www.palma.cat/",
          es: "https://www.palma.es/es/",
        },
      },
      {
        title:
          "Departament de Cultura, Patrimoni i Política Lingüística - Consell de Mallorca",
        image: "consell-de-mallorca.png",
        size: 1.5,
        original: true,
        link: {
          cat: "https://web.conselldemallorca.es/cultura-i-patrimoni",
          es: "https://web.conselldemallorca.es/es/web/web1/cultura-y-patrimonio",
        },
      },
      // {
      //   title:
      //     "Departament de Cultura, Patrimoni i Política Lingüística - Consell de Mallorca ",
      //   image: "support-of-departament-de-cultura.png",
      //   size: 1,
      //   original: true,
      //   link: {
      //     cat: "https://web.conselldemallorca.cat/cultura-i-patrimoni",
      //     es: "https://web.conselldemallorca.cat/es/cultura-y-patrimonio",
      //   },
      // },
      // {
      //   title: "ICEC",
      //   image: "support-of-icec.png",
      //   size: 1.2,
      //   original: true,
      //   link: {
      //     cat: "https://icec.gencat.cat/ca/inici",
      //     es: "https://icec.gencat.cat/ca/inici",
      //   },
      // },
      // {
      //   title: "Acción Cultural Española",
      //   image: "support-of-ace.png",
      //   size: 1.2,
      //   original: true,
      //   link: {
      //     cat: "https://www.accioncultural.es",
      //     es: "https://www.accioncultural.es",
      //   },
      // },
      // {
      //   title: "Mallorca Film Comission",
      //   image: "support-of-mallorca-film-comission.png",
      //   size: 1.5,
      //   original: true,
      //   link: {
      //     cat: "https://mallorcafilmcommission.net",
      //     es: "https://mallorcafilmcommission.net/es/",
      //   },
      // },
    ],
  },
];

export default supportOf;
