
import { defineComponent, ref, computed } from "vue";
import Button from "@/components/Button.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import HeroFestivalSlogan from "@/components/hero/HeroFestivalSlogan.vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import TrailerModal from "@/components/TrailerModal.vue";
import { useMediaQuery } from "@vueuse/core";

export default defineComponent({
  components: {
    HeroFestivalSlogan,
    Button,
    TrailerModal,
  },
  setup() {
    const isModalOpen = ref(false);
    const { currentLang } = useRouteWithLang();
    const baseURL = process.env.BASE_URL;
    const scheduleDownloadLink = process.env.VUE_APP_SCHEDULE_DOWNLOAD_LINK;

    const videoURL = `${baseURL}video/AMFF_web_background_weboptimized3.mp4`;
    const isMobile = useMediaQuery("(max-width: 1023px)");

    function isDateBefore(date: Date) {
      return (
        new Date(date.toDateString()) > new Date(new Date().toDateString())
      );
    }

    function isDateAfter(date: Date) {
      return (
        new Date(date.toDateString()) < new Date(new Date().toDateString())
      );
    }
    const homeHeroButtons = computed(() => {
      return [
        {
          title: "home.sign_up_title_1",
          subtitle: "home.sign_up_subtitle_1",
          link: "https://filmmakers.festhome.com/festival/atlantida-mallorca-film-fest",
          show: isDateBefore(new Date(2022, 5, 16)),
        },
        {
          title: "home.sign_up_title_2",
          subtitle: "home.sign_up_subtitle_2",
          link: "https://enfilmin.typeform.com/to/ElMHmRon",
          show: isDateAfter(new Date(2022, 4, 30)),
        },
      ].filter((item) => item.show);
    });

    return {
      AnchorLink,
      isModalOpen,
      currentLang,
      baseURL,
      scheduleDownloadLink,
      homeHeroButtons,
      videoURL,
      isMobile,
    };
  },
});
