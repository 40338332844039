
import { defineComponent, computed, onMounted } from "vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import PageTitle from "@/components/PageTitle.vue";
import PostCard from "@/components/PostCard.vue";
import PostCardSkeleton from "@/components/PostCardSkeleton.vue";
import Button from "@/components/Button.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import usePosts from "@/composables/usePosts";
import { useRouteWithLang } from "@/composables/useRouteWithLang";

export default defineComponent({
  components: {
    PageTitle,
    PostCard,
    PostCardSkeleton,
    ArrowIcon,
    Button,
  },
  props: {
    limit: {
      type: Number,
      default: 6,
    },
  },
  setup(props) {
    const { currentLang } = useRouteWithLang();
    const {
      fetchPosts,
      posts: _posts,
      isFetching,
    } = usePosts(currentLang.value);
    onMounted(async () => {
      if (!_posts.value.length) {
        await fetchPosts();
      }
    });
    const posts = computed(() => {
      return _posts.value.slice(0, props.limit);
    });

    return {
      posts,
      isFetching,
      AnchorLink,
    };
  },
});
