
import { defineComponent } from "vue";
// import AnchorLink from "@/components/AnchorLink.vue";
// import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import StickerFrame from "./festivalEventsOverview/StickerFrame.vue";
import PageTitle from "@/components/PageTitle.vue";
// import SeeTheFullProgram from "@/components/SeeTheFullProgram.vue";

export default defineComponent({
  components: {
    // AnchorLink,
    // ArrowIcon,
    StickerFrame,
    PageTitle,
    // SeeTheFullProgram,
  },
  setup() {
    return {
      baseURL: process.env.BASE_URL,
    };
  },
});
